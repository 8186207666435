import React , { useState }  from 'react';
import { Box, Typography, Grid, Button, Modal } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import image1 from '../assets/images/fruity.png';
import image2 from '../assets/images/quintellix.png';
import image3 from '../assets/images/montdoc.png';
import image4 from '../assets/images/budgetMaster.png';
import image5 from '../assets/images/taskmanager.png';
import image6 from '../assets/images/smartpass.png';
import image7 from '../assets/images/weekclycyber.png'
import  './Styles/Project.css';
import { useTheme } from '@mui/material/styles';



// Composant de carte de projet
const ProjectCard = ({ title, description, imageUrl, liveDemoUrl }) => {

  const [openModal, setOpenModal] = useState(false); // État pour ouvrir/fermer la modale
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          padding:2,
          textAlign: 'center',
          width: '100%',
          maxWidth: '300px',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Box
            component="img"
            src={imageUrl}
            alt={title}
            sx={{
              borderRadius: '16px',
              width: '90%',
              height: 'auto',
              margin: '0 auto',
            }}
          />
          <Typography variant="h6" sx={{ margin: '0', color: theme.palette.text.primary }}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              margin: '1rem',
              color: theme.palette.text.secondary,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              cursor: 'pointer', // Change le curseur pour indiquer que le texte est cliquable
            }}
            onClick={handleOpenModal} // Ouvre la modale au clic
          >
            {description}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
          <Button
            variant="outlined"
            sx={{
              color: theme.palette.text.primary,
              borderColor: theme.palette.divider,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
            href={liveDemoUrl}
            target="_blank"
          >
            View Project
          </Button>
        </Box>
      </Box>

      {/* Modale pour afficher la description complète */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>
          <Button onClick={handleCloseModal} sx={{ marginTop: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};



// Composant pour afficher la section des projets
const ProjectsSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const projects = [
    {
      title: 'WeekclyCyber',
      description: t('description_for_weeklyCyber'),
      imageUrl:image7,
      liveDemoUrl: 'https://weeklycyber.com/',
    },
    {
      title: 'SMART-PASS',
      description: t('description_for_smartpass'),
      imageUrl: image6,
      liveDemoUrl: 'https://smartpass.lowewilliam.com/',
    },
    {
      title: 'BudgetMaster',
      description: t('description_for_budgetMaster'),
      imageUrl: image4,
      liveDemoUrl: 'https://expensetracker.lowewilliam.com/',
    },
    {
      title: 'Mytaskmanager',
      description: t('description_for_mytaskmanager'),
      imageUrl: image5,
      liveDemoUrl: 'https://mytaskmanager.lowewilliam.com/',
    },
    {
      title:'Quintellix',
      description: t('description_for_company_website'),
      imageUrl: image2,
      liveDemoUrl: 'https://quintellixwebsite.netlify.app/',
    },
    {
      title:'Fruity-Delights',
      description: t('description_for_fruit_sales_platform'),
      imageUrl: image1,
      liveDemoUrl: 'https://fruity-delights.lowewilliam.com/',
    },
    {
      title: 'MontDoc',
      description: t('description_for_mail_management_app'),
      imageUrl: image3,
      liveDemoUrl: 'https://mondoc.netlify.app/',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t('projects_title')}</title>
        <meta name="description" content={t('projects_description')} />
        <meta name="keywords" content="William Lowe, projets, développement web, cybersécurité, projets informatiques, réalisations" />
        <meta property="og:title" content={`${t('projects_intro')} ${t('name')}`} />
        <meta property="og:description" content={t('projects_description')} />
        <meta property="og:url" content="https://lowewilliam.com/" />
      </Helmet>

      <Box  data-aos="" sx={{ padding: 4 }}>
        
        <header className="text-center mb-5">
        <Typography className="Title_projects display-4" variant=  "h2" component="h2" gutterBottom>
          <span  style={{ color: theme.palette.success.main }} > {t('Title_projects')}</span>  
          </Typography>
          </header>
       {/* <Typography variant="h5" sx={{ marginBottom: 2 }}>
          {t('recent_projects')}
        </Typography> */}
        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ProjectCard
                title={project.title}
                description={project.description}
                imageUrl={project.imageUrl}
                liveDemoUrl={project.liveDemoUrl}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ProjectsSection;
