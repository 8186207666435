// src/styles/theme.js
import { createTheme } from '@mui/material/styles';

// Thème clair
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#28a745',
    },
    success: {   // Ajout de la couleur success
      main: '#28a745',  // Vert clair personnalisé
    },
    background: {
      default: '#ffffff',
      paper:   '#fdffff',
    },
  },
});

// Thème sombre
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00bcd4',
    },
    success: {   // Ajout de la couleur success
      main: '#00bcd4',  // Vert plus foncé pour le mode sombre
    },
    background: {
      default: '#121212',
      paper: '#424242',
    },
  },
});

export { lightTheme, darkTheme };
