import React from 'react';
import { Box, Typography, TextField, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Email, Phone, Web, } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles'; // Importer le hook useTheme

const Contact = () => {
  const { t } = useTranslation(); // Utiliser le hook useTranslation pour obtenir les traductions
  const navigate = useNavigate();
  const theme = useTheme();
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.forEach((value, key) => {
      console.log(key, value);
    });

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        navigate('/thankyou'); // Rediriger vers la page de remerciements
      })
      .catch((error) => alert(error));
  };

  return (

    <>
      <Helmet>
        <title>{t('contact_title')}</title>
        <meta name="description" content="Contactez William Lowe pour toute question sur la cybersécurité, le développement web, ou pour discuter d'opportunités professionnelles. Remplissez le formulaire de contact ou utilisez les informations ci-dessous pour nous joindre." />
        <meta name="keywords" content="William Lowe, contacter, contact, cybersécurité, développement web, opportunités professionnelles, adresse email, téléphone" />
        <meta property="og:title" content={t('contact_title')} />
        <meta property="og:description" content="Vous avez une question ou une demande? Contactez William Lowe via le formulaire en ligne ou directement par téléphone ou email." />
      </Helmet>


      <Box data-aos="" sx={{ padding: 4 }}>
        <header className="text-center mb-5">
          <Typography variant="h2" component="h1"  gutterBottom>
           <span style={{ color: theme.palette.success.main }}>
        {t('contact')}</span> 
          </Typography>
        </header>
        <Typography variant="h5" sx={{ marginBottom: 4, textAlign: 'center', color: 'green' }}>
        <span style={{ color: theme.palette.success.main }}>{t('questions')}</span>
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
            <Email fontSize="large" sx={{ color: theme.palette.success.main }}/>
            <Typography variant="h6">{t('email')}</Typography>
            <Typography
              variant="body1"
              component="a"
              href="mailto:lowewilliam237@gmail.com"
              style={{ textDecoration: 'none', color: 'inherit' }}>
              {t('email_address')}
            </Typography>

          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
            <Phone fontSize="large" sx={{ color: theme.palette.success.main }}/>
            <Typography variant="h6">{t('call_me')}</Typography>
            <Typography
              variant="body1"
              component="a"
              href="tel:+33759822676"
              sx={{ textDecoration: 'none', color: 'inherit' }}
            >
              {t('phone_number')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
            <LinkedInIcon fontSize="large" sx={{ color: theme.palette.success.main }} />
            <Typography variant="h6">{t('Join_me')}</Typography>
            <Typography
              variant="body1"
              component="a"
              href="https://www.linkedin.com/in/william-lowe-kamga/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none', color: 'inherit' }}>

              {t('address')}

            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center' }}>
            <Web fontSize="large" sx={{ color: theme.palette.success.main }} />
            <Typography variant="h6">{t('website')}</Typography>
            <Typography
              variant="body1"
              href="https://lowewilliam.com/"
              target="_blank"
              rel="noopener noreferrer"
              component="a"
              sx={{ textDecoration: 'none', color: 'inherit' }}

            >

              {t('website_address')}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ marginBottom: 2, marginTop: 8, textAlign: 'center', color: 'green' }}>
          <span style={{ color: theme.palette.success.main }}>{t('send_email')}</span>
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4, textAlign: 'center' }}>
          {t('responsive_message')}
        </Typography>
        <form name="contact" method="post" data-netlify="true" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="contact" />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField fullWidth label={t('name_field')} name="name" required />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField fullWidth label={t('email_field')} name="email" type="email" required />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label={t('subject')} name="subject" variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label={t('message')} name="message" variant="outlined" multiline rows={4} required />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" className='btn btn-success' color="success">
                {t('send_button')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default Contact;
