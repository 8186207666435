import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { useTheme } from '@mui/material/styles';

const About = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const groupedSkills = t('skills_detail_grouped', { returnObjects: true });

  const education = t('educations', { returnObjects: true });
  const experience = t('experience_items', { returnObjects: true });

  const handleDownloadClick = () => {
    const link = document.createElement('a');
    link.href = '/WILLIAM_LOWE.pdf';
    link.download = 'WILLIAM_LOWE.pdf';
    link.click();
  };

  return (
    <>
      <Helmet>
        <title>{t('about_title')}</title>
        <meta name="description" content={t('description')} />
        <meta name="keywords" content="William Lowe, cybersecurity, web development, skills, education, professional experience" />
        <meta property="og:title" content={t('about_title')} />
        <meta property="og:description" content={t('description')} />
      </Helmet>

      <Box sx={{ px: { xs: 2, md: 6 }, py: 6 }}>
        <Box textAlign="center" mb={6}>
          <Typography variant="h2" gutterBottom sx={{ color: theme.palette.success.main, textTransform: 'uppercase', fontSize: { xs: '2rem', md: '2.5rem' } }}>
            {t('about')}
          </Typography>
          <Typography variant="h6">
            {t('about_intro')} <strong>{t('about_future_intern')}</strong>
          </Typography>
          <Typography variant="body1" mt={2}>{t('about_description')}</Typography>
        </Box>

        <Grid container spacing={4}>
          {/* Experience Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, overflowX: 'hidden' }} elevation={3}>
              <Typography variant="h5" sx={{ color: theme.palette.success.main, mb: 2 }}>{t('experience')}</Typography>
              <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
                {experience.map((exp) => (
                  <li key={exp.role} style={{ marginBottom: 16 }}>
                    <Typography variant="subtitle2" fontWeight="bold">{exp.date}</Typography>
                    <Typography variant="body1">{exp.role}</Typography>
                    <Typography variant="body2" color="text.secondary">{exp.company}</Typography>
                    <Typography variant="body2">{exp.description}</Typography>
                    {exp.type === 'task' && exp.items && (
                      <ul style={{ paddingLeft: '1rem', marginTop: 8 }}>
                        {exp.items.map((item, index) => (
                          <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 9 }}>
                            
                            <Typography variant="body1"><BsFillCheckCircleFill  style={{ color: theme.palette.success.main, marginRight:'5px',fontSize: '1rem', display: 'inline-block'  }} />{item}</Typography>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>

          {/* Skills Section */}
          <Grid item xs={12} md={6}>
  <Paper sx={{ p: 3 }} elevation={3}>
    <Typography variant="h5" sx={{ color: theme.palette.success.main, mb: 2 }}>
      {t('skills')}
    </Typography>

    {Object.entries(groupedSkills).map(([category, items]) => (
      <Box key={category} mb={3}>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{ color: theme.palette.success.main, mb: 1 }}
        >
          {category}
        </Typography>
        <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
          {items.map((skill, idx) => (
            <li key={idx} style={{ padding: '4px 0', borderBottom: '1px solid #eee' }}>
              <Typography variant="body2">{skill}</Typography>
            </li>
          ))}
        </ul>
      </Box>
    ))}

    <Button
      fullWidth
      sx={{
        mt: 2,
        backgroundColor: theme.palette.success.main,
        color: '#fff',
        '&:hover': { backgroundColor: theme.palette.success.dark }
      }}
      onClick={handleDownloadClick}
    >
      {t('download_cv')}
    </Button>
  </Paper>
</Grid>


          {/* Education Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3 }} elevation={3}>
              <Typography variant="h5" sx={{ color: theme.palette.success.main, mb: 2 }}>{t('education')}</Typography>
              <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
                {education.map((edu) => (
                  <li key={edu.degree} style={{ marginBottom: 16 }}>
                    <Typography variant="subtitle2" fontWeight="bold">{edu.date}</Typography>
                    <Typography variant="body1">{edu.degree}</Typography>
                    {edu.description && (
                      <Typography variant="body2" color="text.secondary">{edu.description}</Typography>
                    )}
                    <Typography variant="caption" color="text.secondary">{edu.institution}</Typography>
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default About;
