import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'; // Importer le hook useTranslation
import { useTheme } from '@mui/material/styles'; // Importer le hook useTheme
import Photo from './tof1.png';
import './Styles/Home.css';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { useEffect } from 'react';

const Home = () => {
  const { t } = useTranslation(); // Utiliser le hook useTranslation pour accéder aux traductions
  const navigate = useNavigate();
  const theme = useTheme();
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--couleur-secondaire', theme.palette.success.main);
  }, [theme]);

  const handleRecruitMeClick = () => {
    navigate('/contact');
  };

  return (
    <>
      <Helmet>
        <title>{t('name')} - Cybersecurity & Management Student</title>
        <meta name="description" content={t('description')} />
        <meta name="keywords" content="William Lowe, cybersecurity, web development, data management, cybersecurity internship, cybersecurity student, cybersécurité, stage cybersécurité, développeur web" />
        <meta property="og:title" content={t('name')} />
        <meta property="og:description" content={t('description')} />
        <meta property="og:image" content={Photo} />
        <meta property="og:url" content="https://lowewilliam.com/" />
      </Helmet>
       
      <Box
     
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Change la direction des éléments
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 5,
          paddingLeft: 6,
          paddingRight:0,
          gap:5,
          className: 'container',
          
        }}
      >
        {/* Div contenant l'image */}
        <Box

          sx={{
            order: { xs: 1, md: 2 }, // Positionne l'image en premier sur les petits écrans, et en deuxième sur les grands écrans
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: { xs: 4, md: 0 },
          }}
        >
         <Box
          className="cadre"
      sx={{
        position: 'relative',
        display: 'inline-block',
        '&::before': {
          content: '""',
          position: 'absolute',
          borderStyle: 'solid',
          borderWidth: '10px',
          borderColor: `transparent ${theme.palette.success.main} ${theme.palette.success.main} transparent`,
          top: '-20px',
          left: '-40px',
          transform: 'scaleX(1)',
          zIndex: 1,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          borderStyle: 'solid',
          borderWidth: '10px',
          borderColor: `${theme.palette.success.main} transparent transparent ${theme.palette.success.main}`,
          bottom: '-20px',
          right: '50px',
          zIndex: 1,
        },
      }}
    >
      <img
        src={Photo}
        alt="My portrait"
        style={{
          width: '80%',
          height: '80%',
          objectFit: 'cover',
          borderRadius: '10px',
          position: 'relative',
          zIndex: 2,
        }}
      />
    </Box>
           
            
          
        </Box>

        {/* Div contenant le texte */}
        <Box
          sx={{
            order: { xs: 2, md: 1 }, // Positionne le texte en deuxième sur les petits écrans, et en premier sur les grands écrans
            flex: 1,
            textAlign: { xs: 'center', md: 'left' },
            padding:0, // Ajouter du padding pour un peu d'espace
            marginRight: {xs:4, md:0},

          }}
        >
          <Typography className='texteinto1' variant="h5">
          <WavingHandIcon sx={{ color: theme.palette.success.main, marginRight:'8px' }} /> 
            {t('intro')} <span style={{ color: theme.palette.success.main }} className='texteintro2' > {t('name')}</span>
          </Typography>
          <Typography className="typewriter" sx={{ color:theme.palette.success.main, fontWeight: "bold", marginTop: 2 }} variant="h5">
            {t('verb')} <span style={{ color: theme.palette.success.main }} className='role'>{t('role')}</span>
          </Typography>
          <Typography variant="body1" sx={{ marginTop: '15px', fontSize: '20px' }}>
            {t('description')}
          </Typography>
          <Typography variant="body1" sx={{ marginTop: '15px', fontSize: '20px' }}>
            {t('description_2')}
          </Typography>
          <Button variant="contained" color='success'  className='texteinto3 mt-3'  onClick={handleRecruitMeClick}>
            {t('hire_me')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Home;
